import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  addPlace,
  deletePlace,
  fetchCategories,
  fetchCountries,
  fetchPlaces,
  updatePlace,
} from "../../utils/apis";
import PlacesManagementDialog from "./components/PlacesModal";
import DataTable from "./placesManagementListTable";

const PlacesManagement = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(true);
  const [places, setPlaces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
  });
  const [modalTitle, setModalTitle] = useState("");
  const [editValues, setEditValues] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setModalTitle("Add Place");
    setOpen(true);
  };

  const handleModalEditClick = (id, data) => {
    setModalTitle("Edit Place");
    setOpen(true);
    setEditValues({
      id,
      ...data,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async function () {
      const result = await fetchCountries();
      if (result.statusCode === 200) {
        setCountries(
          result.data.countries?.filter((country) => country.status === true)
        );
      }
    })();
  }, [setCountries]);

  useEffect(() => {
    (async function () {
      const result = await fetchCategories();
      if (result.statusCode === 200) {
        setCategories(
          result.data.categories?.filter((country) => country.status === true)
        );
      }
    })();
  }, [setCountries]);

  const handlePlaceEdit = async (id, data) => {
    try {
      const result = await updatePlace(id, data, enqueueSnackbar);
      if (result?.statusCode === 200) {
        const updatedPlace = result?.data;
        setPlaces((prevPlaces) => {
          const index = prevPlaces.findIndex(
            (place) => place?.shortId === updatedPlace?.shortId
          );
          if (index > -1) {
            const updatedPlaces = [...prevPlaces];
            updatedPlaces[index] = updatedPlace;
            return updatedPlaces;
          }
          return prevPlaces;
        });
      }
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleAddPlace = async (data) => {
    try {
      const result = await addPlace(data, enqueueSnackbar);
      setPlaces([result.data?.place, ...places]);
    } catch (error) {
      error &&
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
    }
  };

  const handleDelete = async (ids = []) => {
    try {
      const result = await deletePlace(ids, enqueueSnackbar);
      if (result?.statusCode === 200) {
        setPlaces((prev) => {
          const remainingPlaces = prev.filter(
            (place) => !ids.some((id) => place.shortId === id)
          );
          if (remainingPlaces.length === 0) {
            setFetch(true);
          } else {
            return remainingPlaces;
          }
        });
      }
    } catch (error) {
      enqueueSnackbar(`${error?.message}`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setPagination((prev) => ({
      ...prev,
      perPage: event.target.value,
      page: 0,
    }));
    setFetch(true);
  };

  // Handle page change
  const handleChangePage = (_, newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    setFetch(true);
  };

  useEffect(() => {
    if (fetch) {
      (async function () {
        const result = await fetchPlaces(enqueueSnackbar, {
          page: pagination.page,
          perPage: pagination.perPage,
        });
        if (result.statusCode === 200) {
          const {
            data,
            _metadata: {
              pagination: { page, perPage, total },
            },
          } = result;
          // Updated to correctly set the places data from the API response
          setPlaces(data);
          setPagination({
            page,
            perPage,
            total,
          });
        }
      })();
    }
    return setFetch(false);
  }, [fetch, enqueueSnackbar, pagination.page, pagination.perPage]);

  return (
    <div>
      <DataTable
        onAdd={handleClickOpen}
        onEdit={handleModalEditClick}
        Places={places} // Updated to match state variable
        onDelete={handleDelete}
        onChangeStatus={() => {}}
        pagination={pagination}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
      />
      <PlacesManagementDialog
        countries={countries}
        categories={categories}
        editValues={editValues}
        setEditValues={setEditValues}
        onAdd={handleAddPlace}
        onEdit={handlePlaceEdit}
        open={open}
        handleClose={handleClose}
        title={modalTitle}
      />
    </div>
  );
};

export default PlacesManagement;
